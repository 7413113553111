import { createContext, useState } from "react";

export const DetailsForDekoart = createContext();
export const MyDekoartItems = ({ children }) => {
  const [itemList, setItemList] = useState({ typeLang: (localStorage.getItem("i18nextLng")) });
  return (
    <DetailsForDekoart.Provider value={[itemList, setItemList]}>
      {children}
    </DetailsForDekoart.Provider>
  );
};
